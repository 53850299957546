import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useOrderFunctions from './orderFunctions';
import AccountModal from './AccountModal';
import StrategyModal from './StrategyModal';
import ConfirmStopModal from './ConfirmStopModal';
import './App.css';

const App = () => {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();

  const auth0Nick = user ? user.nickname : '';

  const {
    selectedAccounts,
    selectedStrategies,
    runningStrategies,
    strategyNickname,
    tradeOption,
    tradeOptionTwo,
    ticker, 
    accounts,
    amountLots,
    amountLotsTwo,
    lowerLimit,
    upperLimit,
    stepLimit,
    profitLimit,
    allowPivotShift,
    lowerLimitTwo,
    upperLimitTwo,
    stepLimitTwo,
    profitLimitTwo,
    loading,
    success,
    confirmStopStrategy,
    setTradeOption,
    setTradeOptionTwo,
    setTicker,
    setLotAmount,
    setLotAmountTwo,
    setLowerLimit,
    setUpperLimit,
    setStepLimit,
    setProfitLimit,
    setPivotShift,
    setLowerLimitTwo,
    setUpperLimitTwo,
    setStepLimitTwo,
    setProfitLimitTwo,
    setStrategyNickname,
    handleSelectAccount,
    handleSelectStrategy,
    sendNewAccountData,
    deleteAccount,
    executeStrategy,
    stopAllStrategies
  } = useOrderFunctions(auth0Nick);
  
  const [areFieldsFilled, setAreFieldsFilled] = useState(false);
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showConfirmStopModal, setShowConfirmStopModal] = useState(false);
  const [selectedStrategyToStop] = useState(null);
  const [isSecondaryEnabled, setIsSecondaryEnabled] = useState(false);

  useEffect(() => {
    const checkFieldsFilled = () => {
      if (
        tradeOption &&
        selectedAccounts.length !== 0 &&
        ticker &&
        amountLots &&
        lowerLimit &&
        upperLimit &&
        stepLimit &&
        profitLimit
      ) {
        setAreFieldsFilled(true);
      } else {
        setAreFieldsFilled(false);
      }
    };

    checkFieldsFilled();
  }, [
    tradeOption,
    selectedAccounts.length,
    ticker,
    amountLots,
    lowerLimit,
    upperLimit,
    stepLimit,
    profitLimit
  ]);

  const handleDeleteAccount = () => {
    selectedAccounts.forEach(accountNickname => deleteAccount(accountNickname));
  };

  const toggleSecondaryConfig = (event) => {
    setIsSecondaryEnabled(event.target.value === 'Yes');
  };

  const handleStartStrategyClick = () => {
    setShowStrategyModal(true);
  };

  const handleCloseStrategyModal = () => {
    setShowStrategyModal(false);
  };

  const handleConfirmStrategyModal = async () => {
    await executeStrategy();
    setShowStrategyModal(false);
  };

  const handleAddNewAccountClick = () => {
    setShowAccountModal(true);
  };

  const handleCloseAccountModal = () => {
    setShowAccountModal(false);
  };

  const handleConfirmAccountModal = (newAccount) => {
    sendNewAccountData(newAccount);
    setShowAccountModal(false);
  };

  const handleConfirmCloseModal = () => {
    if (selectedStrategies.length === 1) {
      confirmStopStrategy(selectedStrategies[0])
    } else {
      // Do function for multiple closes (TBA)
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const strategyData = {
    tradeOption,
    ticker,
    amountLots,
    lowerLimit,
    upperLimit,
    stepLimit,
    profitLimit,
    tradeOptionTwo,
    amountLotsTwo,
    lowerLimitTwo,
    upperLimitTwo,
    stepLimitTwo,
    profitLimitTwo,
  };

  return (
    <div>
      <header className="app-header">
        {isAuthenticated && (
          <div className="profile-section">
            <div className="profile-info">
              <div className="profile-text">
                <br></br>
                <p>{user.nickname} &nbsp;</p>
              </div>
              <img src={user.picture} alt={user.name} className="profile-picture" />
              <button className="logout-button" onClick={() => logout({ returnTo: window.location.origin })}>Log out</button>
            </div>
          </div>
        )}
      </header>

      {!isAuthenticated && (
        <div>
          <div className="logo-container">
            <img src="https://sofex.finance/wp-content/uploads/2023/09/logo-sf.png" alt="Sofex Logo" className="top-image" />
        </div>
        <br></br>
          <div>
            <h1>Sofex Meta</h1>
          </div>
        </div>
      )}

    <div className="main-content">
      {!isAuthenticated ? (
        <button onClick={() => loginWithRedirect()}>Authenticate</button>
      ) : (   
        <>
          <div className="app-container">
            <h2 style={{ position: 'absolute', left: 60, top: 20, fontSize: '40px', color: '#d49500' }}>MT5 Control Panel</h2>
            <br></br>
            <h3 style={{ position: 'absolute', left: 710, top: 30 }}>Select account(s) to operate:</h3>
            <button onClick={handleAddNewAccountClick} style={{ position: 'absolute', left: 1020, top: 80, fontSize: '15px', width: '200px'}}>Add New Account</button>
            <button onClick={handleDeleteAccount} style={{ position: 'absolute', left: 1020, top: 130, fontSize: '15px', width: '200px' }} disabled={selectedAccounts.length === 0}>Delete Account</button>

            <div className="account-selection">
              <div className="dropdown">
                <select multiple={true} className="dropdown">
                {accounts.map(account => (
                  <option key={account.name} onClick={() => handleSelectAccount(account.name)}>
                  {selectedAccounts.includes(account.name) ? '✅ ' : ''}{account.name}
                  </option>
                  ))}
                </select>
              </div> 
            </div> 
            <div className="config-section">
              <div style={{ position: 'absolute', left: 60, top: 100 }}>
                <h2>Setup parameters for a strategy.</h2>
            </div>
            <div className="config-section">
              <div style={{ position: 'absolute', left: 60, top: 140 }}>
                <h3>Allow secondary parameters?</h3>
              </div>
            </div>
            <div style={{ position: 'absolute', left: 320, top: 160 }}>
              <select onChange={toggleSecondaryConfig}>
                <option>No</option>
                <option>Yes</option>
              </select>
            </div>
            
          <div className="background-oval-left">
            <div style={{ position: 'absolute', left: 40, top: 90 }}>
              <h3>Trade Side</h3>
              <select value={tradeOption} onChange={(t) => setTradeOption(t.target.value)}>
                <option value="None">None</option>
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
              </select>
            </div>

            <div style={{ position: 'absolute', left: 160, top: 10, height: 50}}>
            <h3>Start of Range</h3>
              <input value={upperLimit} onChange={(t) => setUpperLimit(t.target.value)}
                  type="number"
                  placeholder="----"
              />
            </div>

            <div style={{ position: 'absolute', left: 340, top: 10, height: 50}}>
            <h3>Step For This Side</h3>
              <input value={stepLimit} onChange={(t) => setStepLimit(t.target.value)}
                  type="number"
                  placeholder="----"
              />
            </div>

            <div style={{ position: 'absolute', left: 160, top: 170, height: 50 }}>
                <h3>Lots For This Side.</h3>
                  <input value={amountLots} onChange={(o) => setLotAmount(o.target.value)} type="number" placeholder="----"/>
            </div>

            <div style={{ position: 'absolute', left: 340, top: 170, height: 50 }}>
                <h3>Allow Pivot Shift?.</h3>
                <select value={allowPivotShift} onChange={(t) => setPivotShift(t.target.value)}>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
            </div>

            <div style={{ position: 'absolute', left: 340, top: 90, height: 50}}>
            <h3>Limit For This Side</h3>
              <input value={profitLimit} onChange={(t) => setProfitLimit(t.target.value)}
                  type="number"
                  placeholder="----"
              />
            </div>

            <div style={{ position: 'absolute', left: 160, top: 90, height: 50}}>
            <h3>End of Range</h3>
              <input value={lowerLimit} onChange={(t) => setLowerLimit(t.target.value)}
                  type="number"
                  placeholder="----"
              />
            </div>
          </div>

          {/*Trade Pair Selection */}
            <div style={{ position: 'absolute', left: 770, top: 230 }}>
                <h2>Trade Pair</h2>
                <select value={ticker} onChange={(p) => setTicker(p.target.value)}>
                  <option value="none">None</option>
                  <option value="XAUUSD">XAUUSD</option>
                  <option value="XAGUSD">XAGUSD</option>
                  <option value="EURUSD">EURUSD</option>
                  <option value="EURGBP">EURGBP</option>
                  <option value="USDGBP">USDGBP</option>
                  <option value="JPYGBP">JPYGBP</option>
                  <option value="JPYUSD">JPYUSD</option>
                  <option value="BTCUSD">BTCUSD</option>
                  <option value="ETHUSD">ETHUSD</option>
                  <option value="SOLUSD">SOLUSD</option>
                </select>
            </div>
            
          {/* Second configuration*/}
          
          <div className='background-oval-right'>
            <div style={{ position: 'absolute', left: 160, top: 170, height: 50 }}>
                <h3>Lots For This Side.</h3>
                  <input value={amountLotsTwo} onChange={(o) => setLotAmountTwo(o.target.value)} type="number" placeholder="----" disabled={!isSecondaryEnabled} />
            </div>


            <div style={{ position: 'absolute', left: 40, top: 90 }}>
                <h3>Trade Side</h3>
                <select value={tradeOptionTwo} onChange={(t) => setTradeOptionTwo(t.target.value)} disabled={!isSecondaryEnabled}>
                  <option value="none">None</option>
                  <option value="Buy">Buy</option>
                  <option value="Sell">Sell</option>
                </select>
            </div>

            <div style={{ position: 'absolute', left: 160, top: 10, height: 50 }}>
                <h3>Start of Range</h3>
                  <input value={upperLimitTwo} onChange={(t) => setUpperLimitTwo(t.target.value)} type="number" placeholder="----" disabled={!isSecondaryEnabled} />
            </div>

            <div style={{ position: 'absolute', left: 340, top: 10, height: 50 }}>
                <h3>Step For This Side</h3>
                  <input value={stepLimitTwo} onChange={(t) => setStepLimitTwo(t.target.value)} type="number" placeholder="----" disabled={!isSecondaryEnabled} />
            </div>

            <div style={{ position: 'absolute', left: 340, top: 90, height: 50 }}>
                <h3>Limit For This Side</h3>
                  <input value={profitLimitTwo} onChange={(t) => setProfitLimitTwo(t.target.value)} type="number" placeholder="----" disabled={!isSecondaryEnabled} />
            </div>

            <div style={{ position: 'absolute', left: 160, top: 90, height: 50 }}>
                <h3>End of Range</h3>
                  <input value={lowerLimitTwo} onChange={(t) => setLowerLimitTwo(t.target.value)} type="number" placeholder="----" disabled={!isSecondaryEnabled} />
            </div>
            </div>

            {/* Start Strategy Button and Modal */}
            <div style={{ position: 'absolute', left: 680, top: 360, width: 300}}>
              <button onClick={handleStartStrategyClick} disabled={!areFieldsFilled}>Start Strategy</button>
            </div>
          </div>
        </div>
        <h3 style={{ position: 'absolute', left: 70, top: 560 }}>Currently running strategies:</h3>
      <div>
        <div className="dropdown-strategies">
          <div className="dropdown">
            <select multiple={true} className="dropdown">
              {runningStrategies.length > 0 ? (
                runningStrategies.map((strategy) => (
                  <option key={strategy} onClick={() => handleSelectStrategy(strategy)}>
                    {selectedStrategies.includes(strategy) ? '✅ ' : ''}{strategy}
                  </option>
                ))
              ) : (
                <option disabled>No strategies available</option>
              )}
            </select>
          </div>
        </div>
        {loading && <i className="fas fa-spinner fa-spin"></i>}
        {success && <i className="fas fa-check-circle"></i>}
        <br></br>
        </div>
        <div style={{ position: 'absolute', left: 1050, top: 610, width: 300}}>
              <button onClick={handleConfirmCloseModal} className='stop-all-button' disabled={selectedStrategies.length === 0}>Stop Selected</button>
        </div> 
        <div style={{ position: 'absolute', left: 1300, top: 610, width: 300}}>
              <button onClick={stopAllStrategies} className='stop-all-button'>Force Stop All</button>
        </div>
        </>
      )}

      <AccountModal
          show={showAccountModal}
          handleClose={handleCloseAccountModal}
          handleConfirm={handleConfirmAccountModal}
      />

      <StrategyModal 
          show={showStrategyModal} 
          handleClose={handleCloseStrategyModal} 
          handleConfirm={handleConfirmStrategyModal} 
          executeStrategy={executeStrategy}
          strategyData={strategyData}
          isSecondaryEnabled={isSecondaryEnabled}
          stratNickname={strategyNickname}
          setStratNickname={setStrategyNickname}
      />

      <ConfirmStopModal
        show={showConfirmStopModal}
        handleClose={() => setShowConfirmStopModal(false)}
        handleConfirm={confirmStopStrategy}
        strategyNickname={selectedStrategyToStop}
      />

      <footer>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <hr />

        <div>
          <a href="https://sofex.finance">Sofex Finance Website</a>
          <br />
          <a href="https://sofex.io">Sofex Algorithms Website</a>
        </div>
        <h4>© Sofex International LTD 2024</h4>
        <h4>Version 1.0.5</h4>
      </footer>
    </div>
  </div>
  );
};

export default App;
