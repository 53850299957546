import { useState, useEffect } from 'react';
import axios from 'axios';

const token = "65d68a93-1ab4-460e-8f0c-d9491b9d9ae6";

const useOrderFunctions = (auth0Nick) => {
  const [runningStrategies, setRunningStrategies] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [strategyNickname, setStrategyNickname] = useState('');
  const [selectedStrategies, setSelectedStrategies] = useState([])
  const [tradeOption, setTradeOption] = useState('');
  const [tradeOptionTwo, setTradeOptionTwo] = useState('');
  const [ticker, setTicker] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [amountLots, setLotAmount] = useState('');
  const [amountLotsTwo, setLotAmountTwo] = useState('')
  const [lowerLimit, setLowerLimit] = useState('')
  const [upperLimit, setUpperLimit] = useState('');
  const [stepLimit, setStepLimit] = useState('');
  const [profitLimit, setProfitLimit] = useState('');
  const [allowPivotShift, setPivotShift] = useState('')
  const [lowerLimitTwo, setLowerLimitTwo] = useState('')
  const [upperLimitTwo, setUpperLimitTwo] = useState('');
  const [stepLimitTwo, setStepLimitTwo] = useState('');
  const [profitLimitTwo, setProfitLimitTwo] = useState('');

  const areFieldsFilled = tradeOption && ticker && amountLots && lowerLimit && upperLimit && stepLimit && profitLimit && selectedAccounts.length > 0;

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!auth0Nick) {
        return;
      }

      try {
        const response = await axios.get(`https://api.sofexmeta.com/get_account_nicknames/${auth0Nick}`, {
          headers: {
            'Auth': token,
          },
        });
        const data = response.data;
        if (data.nicknames && Array.isArray(data.nicknames)) {
          // Extract Nickname property from each object
          const accountNicknames = data.nicknames.map((item, index) => ({ id: index + 1, name: item.Nickname }));
          setAccounts(accountNicknames);
        } else {
          console.error('Unexpected response format:', data);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, [auth0Nick]);

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      fetchStrategies(auth0Nick);
    }
  }, [selectedAccounts.length, auth0Nick]);

  const fetchStrategies = async (account) => {
    try {
      const response = await axios.get(`https://api.sofexmeta.com/get_strategies/${account}`, {
        headers: {
          'Auth': token,
        },
      });
      setRunningStrategies(response.data);
    } catch (error) {
      console.error('Error fetching strategies:', error);
    }
  };

  useEffect(() => {
    if (auth0Nick) {
      fetchStrategies(auth0Nick);
      const interval = setInterval(() => {
        fetchStrategies(auth0Nick);
      }, 60000); 

      return () => clearInterval(interval); 
    }
  }, [auth0Nick]);

  const handleSelectAccount = (accountNickname) => {
    setSelectedAccounts(prev =>
      prev.includes(accountNickname) ? prev.filter(nick => nick !== accountNickname) : [...prev, accountNickname]
    );
  };

  const handleSelectStrategy = (strategy) => {
    setSelectedStrategies((prevSelected) => {
        if (prevSelected.includes(strategy)) {
            return prevSelected.filter((s) => s !== strategy);
        } else {
            return [...prevSelected, strategy];
        }
    });
  };

  const executeStrategy = async () => {
    console.log("Attempting to execute strategy");
    const strategyData = {
      auth0Nick,
      strategyNickname,
      tradeOption,
      ticker,
      amountLots,
      lowerLimit,
      upperLimit,
      stepLimit,
      profitLimit,
      allowPivotShift,
      tradeOptionTwo,
      amountLotsTwo,
      lowerLimitTwo,
      upperLimitTwo,
      stepLimitTwo,
      profitLimitTwo,
    };


    const sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    for (const accountId of selectedAccounts)  {
      try {   
        strategyData.account = accountId
        const res = await sendStrategyData(strategyData);
        if (res) {
            console.log("Executed strategy for ", accountId, " succesfully");
        }
        await sleep(30000);
      } catch (error) {
        console.error('There was a problem sending the strategy data.', error);
        return false;
      }
    }
    return true
  }

  const addNewAccount = (newAccount) => {
    const updatedAccounts = [...accounts, { id: accounts.length + 1, name: newAccount.nickname }];
    setAccounts(updatedAccounts);
  };

  const sendNewAccountData = async (newAccount) => {
    const payload = {
      nickname: newAccount.nickname,
      username: newAccount.username,
      password: newAccount.password,
      server: newAccount.server
    };
  
    try {
      const response = await axios.post(`https://api.sofexmeta.com/add_account/${auth0Nick}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': token
        },
      });
      console.log('New account added successfully:', response.data);
      addNewAccount(newAccount);
    } catch (error) {
      console.error('Error adding new account:', error);
    }
  };

  const deleteAccount = async (nickname) => {
    try {
      await axios.delete(`https://api.sofexmeta.com/delete_account/${nickname}`, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': token,
        }
      });
      setAccounts(prevAccounts => prevAccounts.filter(account => account.name !== nickname));
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const sendStrategyData = async (strategyData) => {
    try {
      const response = await axios.post("https://api.sofexmeta.com/load_strategy", strategyData, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': token,
        },
      });
      console.log('Strategy data sent successfully:', response.data);
      setRunningStrategies([...runningStrategies, strategyData.strategyNickname]);
      return true;
    } catch (error) {
      console.error('Error sending strategy data:', error);
      return false;
    }
  };

  const stopAllStrategies = async () => {
    try {
      const response = await axios.get(`https://api.sofexmeta.com/get_strategies/${auth0Nick}`, {
        headers: {
          'Auth': token,
        },
      });
      const allStrategies = response.data;
  
      for (const strategy of allStrategies) {
        await confirmStopStrategy(strategy);
      }
    } catch (error) {
      console.error('Error stopping all strategies:', error);
    }
  };

  const confirmStopStrategy = async (selectedStrategyToStop) => {
    if (!auth0Nick || !selectedStrategyToStop) {
      console.error('Missing auth0Nick or strategy nickname');
      return;
    }
  
    const payload = {
      strategyNickname: selectedStrategyToStop,
    };
    
    try {
      await axios.post(`https://api.sofexmeta.com/stop_strategy/${auth0Nick}`, payload, {
        headers: {
          'Auth': token,
        },
      });
      setRunningStrategies((prevStrategies) => prevStrategies.filter(strategy => strategy !== selectedStrategyToStop));
    } catch (error) {
      console.error('Error stopping strategy:', error);
    }
  };

  return {
    selectedAccounts,
    selectedStrategies,
    runningStrategies,
    strategyNickname,
    tradeOption,
    tradeOptionTwo,
    ticker,
    accounts,
    amountLots,
    amountLotsTwo,
    lowerLimit,
    upperLimit,
    stepLimit,
    profitLimit,
    lowerLimitTwo,
    upperLimitTwo,
    stepLimitTwo,
    profitLimitTwo,
    allowPivotShift,
    areFieldsFilled,
    sendStrategyData, 
    setAccounts,
    setSelectedAccounts,
    confirmStopStrategy,
    setRunningStrategies,
    setSelectedStrategies,
    setStrategyNickname,
    setTradeOption,
    setTradeOptionTwo,
    setTicker,
    setLotAmount,
    setLotAmountTwo,
    setLowerLimit,
    setUpperLimit,
    setStepLimit,
    setProfitLimit,
    setLowerLimitTwo,
    setUpperLimitTwo,
    setStepLimitTwo,
    setProfitLimitTwo,
    setPivotShift,
    handleSelectAccount,
    handleSelectStrategy,
    addNewAccount,
    sendNewAccountData,
    deleteAccount,
    executeStrategy,
    stopAllStrategies
  };
};

export default useOrderFunctions;
