import React from 'react';
import './ConfirmStopModal.css';

const ConfirmStopModal = ({ show, handleClose, handleConfirm, strategyNickname }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Confirm Stop Strategy</h2>
        <p>Are you sure you want to stop strategy <strong>{strategyNickname}</strong>?</p>
        <div className="modal-buttons">
          <button className='cancel-button' onClick={handleClose}>Cancel</button>
          <button className='confirm-button' onClick={handleConfirm}>Stop Strategy</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStopModal;