import React, { useState, useEffect } from 'react';
import './StrategyModal.css';

const StrategyModal = ({ show, 
  handleClose, 
  handleConfirm, 
  strategyData, 
  isSecondaryEnabled, 
  stratNickname, 
  setStratNickname, 
  executeStrategy }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      if (stratNickname) {
        setIsFormValid(true);
        setStratNickname(stratNickname);
      } else {
        setIsFormValid(false);
      }
    };
    checkFormValidity();
  }, [stratNickname, setStratNickname]);

  if (!show) {
    return null;
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  
  const handleSubmit = async () => {
    console.log("Setting strategy nickname");
    setStratNickname(stratNickname);
    setLoading(true);
    setSuccess(false);
    const res = await executeStrategy();
    setLoading(false);
    if (res) {
      console.log("Sent strategy data successfully");
      setSuccess(true);
      handleConfirm();
    } else {
      console.error('Failed to send strategy data');
      setSuccess(false);
    }

    await sleep(5000);
    setSuccess(false);
    handleClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Strategy Summary</h2>
        <div className="modal-field">
          <strong>Ticker:</strong> {strategyData.ticker}
        </div>
        <div className="modal-field">
          <strong>Trade Side:</strong> {strategyData.tradeOption}
        </div>
        <div className="modal-field">
          <strong>Amount Lots:</strong> {strategyData.amountLots}
        </div>
        <div className="modal-field">
          <strong>Lower Limit:</strong> {strategyData.lowerLimit}
        </div>
        <div className="modal-field">
          <strong>Upper Limit:</strong> {strategyData.upperLimit}
        </div>
        <div className="modal-field">
          <strong>Step Limit:</strong> {strategyData.stepLimit}
        </div>
        <div className="modal-field">
          <strong>Profit Limit:</strong> {strategyData.profitLimit}
        </div>
        {isSecondaryEnabled && (
          <>
            <h3>Secondary Strategy</h3>
            <div className="modal-field">
              <strong>Secondary Trade Side:</strong> {strategyData.tradeOptionTwo}
            </div>
            <div className="modal-field">
              <strong>Secondary Amount Lots:</strong> {strategyData.amountLotsTwo}
            </div>
            <div className="modal-field">
              <strong>Secondary Lower Limit:</strong> {strategyData.lowerLimitTwo}
            </div>
            <div className="modal-field">
              <strong>Secondary Upper Limit:</strong> {strategyData.upperLimitTwo}
            </div>
            <div className="modal-field">
              <strong>Secondary Step Limit:</strong> {strategyData.stepLimitTwo}
            </div>
            <div className="modal-field">
              <strong>Secondary Profit Limit:</strong> {strategyData.profitLimitTwo}
            </div>
          </>
        )}
        <h3>Strategy Name</h3>
        <div>
          <input value={stratNickname} onChange={(o) => setStratNickname(o.target.value)} type="text" placeholder="----"></input>
        </div>
        <div className="modal-buttons">
          <button className='stop-all-button' onClick={handleClose}>Close</button>
          <button className='start-all-button' onClick={handleSubmit} disabled={!isFormValid}>Confirm</button>
        </div>
        <div className="status-icons">
          {loading && <i className="fas fa-spinner fa-spin fa-3x"></i>}
          {success && <i className="fas fa-check-circle fa-3x"></i>}
        </div>
      </div>
    </div>
  );
};

export default StrategyModal;