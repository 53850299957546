import React, { useState, useEffect } from 'react';
import './AccountModal.css';

const AccountModal = ({ show, handleClose, handleConfirm }) => {
  const [nickname, setNickname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [server, setServer] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      if (nickname && username && password && server) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    };

    checkFormValidity();
  }, [nickname, username, password, server]);

  const handleSubmit = () => {
    const newAccount = { nickname, username, password, server };
    handleConfirm(newAccount);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add New Account</h2>
        <div className="modal-field">
          <label>Nickname:</label>
          <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} />
        </div>
        <div className="modal-field">
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="modal-field">
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="modal-field">
          <label>Server:</label>
          <input type="text" value={server} onChange={(e) => setServer(e.target.value)} />
        </div>
        <div className="modal-buttons">
          <button onClick={handleClose} style={{fontSize: '15px'}}>Close</button>
          <button onClick={handleSubmit} style={{fontSize: '15px'}} disabled={!isFormValid}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default AccountModal;
